$langnav-mobile-breakpoint: 1024px;

.bx-langnav__menu {
    --bx-langnav-zindex: 1045;
    --bx-langnav-width: 400px;
    --bx-langnav-height: 30vh;
    --bx-langnav-padding-x: 1rem;
    --bx-langnav-padding-y: 1rem;
    --bx-langnav-color: #000;
    --bx-langnav-bg: #fff;
    --bx-langnav-border-width: 1px;
    --bx-langnav-border-color: var(--bx-border-color-translucent);
    --bx-langnav-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    --bx-langnav-nav-link-padding-y: 0.5rem;
    --bx-langnav-nav-link-padding-x: 0;
    --bx-langnav-ring-offset-shadow: 0 0 #0000;
    --bx-langnav-ring-shadow: 0 0 #0000;
    --bx-langnav-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
        
    position: fixed;
    bottom: 0;
    z-index: var(--bx-langnav-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bx-langnav-color);
    visibility: hidden;
    background-color: var(--bx-langnav-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform .3s ease-in-out;

    @media (min-width: $langnav-mobile-breakpoint) {
        border-radius: 0.5rem;
        bottom: auto;
        max-width: none;
        position: absolute;
        z-index: var(--bx-langnav-zindex);
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity = 0);
        opacity: 0;
        transition: all 0.3s ease-in-out;
        box-shadow: var(--bx-langnav-ring-offset-shadow, 0 0 #0000), var(--bx-langnav-ring-shadow, 0 0 #0000), var(--bx-langnav-shadow);
    }

    .bx-menu__header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: var(--bx-langnav-padding-y) var(--bx-langnav-padding-x);

        @media (min-width: $langnav-mobile-breakpoint) {
            display: none;
        }

        .btn-close {
            display: block;
            padding: calc(var(--bx-langnav-padding-y)* .5) calc(var(--bx-langnav-padding-x)* .5);
            margin-top: calc(-.5* var(--bx-langnav-padding-y));
            margin-right: calc(-.5* var(--bx-langnav-padding-x));
            margin-bottom: calc(-.5* var(--bx-langnav-padding-y));
        }
    }
    .bx-menu__body {
        flex-grow: 1;
        padding: var(--bx-langnav-padding-y) var(--bx-langnav-padding-x);
        overflow-y: auto;

        >ul {
            display: flex;
            flex-direction: column;
            list-style: none;
            padding-left: 0;
            margin: 0;

            > li > a {
                display: flex;
                padding: var(--bx-langnav-nav-link-padding-y) var(--bx-langnav-nav-link-padding-x);
                transition: all 0.3s ease-in-out;
            }
        }
    }
}
.bx-langnav__menu.bx-langnav__menu--end {
    top: 0;
    right: 0;
    width: var(--bx-langnav-width);
    border-left: var(--bx-langnav-border-width) solid var(--bx-langnav-border-color);
    transform: translateX(100%);

    @media (min-width: $langnav-mobile-breakpoint) {
        --bx-langnav-width: 300px;
    
        top: 150%;
        transform: none;
        width: var(--bx-langnav-width);
    }
}
.bx-langnav__menu.hiding, .bx-langnav__menu.show, .bx-langnav__menu.showing {
    visibility: visible;
    
    @media (min-width: $langnav-mobile-breakpoint) {
        top: 141%;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: alpha(opacity=100);
        opacity: 1;
    }
}
.bx-langnav__menu.show:not(.hiding), .bx-langnav__menu.showing {
    transform: none;
}

