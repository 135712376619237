.navbar-main {
    background-color: #fff;
    font-weight: 400;
    height: 45px;
    opacity: 0.95;
}
.nav-main {
    bottom: 0px;
    overflow: scroll;
    position: fixed;
    right: -1000px;
    top: 0px;
    width: 100%;
    
    &.open {
        right: 0px;
    }
    .nav-item-link{
        color: $dark-grey;
        width: 100%;

        &:hover, &:focus {
            color: #000;
            text-decoration: none;
        }
    }
    .nav-item-parent {
        padding-right: 15px;
        position: relative;

        &:after{
            content: "";
            width: 0; 
            height: 0; 
            border-left: 5px solid $dark-grey;
            border-bottom: 5px solid transparent;
            border-top: 5px solid transparent; 
            position: absolute;
            top: 48%;
            right: 15px;
        } 
    }
    > ul >li {
        border-top: solid 1px #E6EAEA;
        
        .nav-item-link {
            border: 1px solid transparent;
            display: block;
            /*padding: 0.5rem;*/
            padding-left: 0.5rem;
            padding-right: 0.5rem;

            &:hover, &:focus {
                background-color: rgba( 243, 244, 246,.7 );
            }
            &:focus {
                background-color: silver;
                border: 1px solid silver;
                color: #fff;
                outline: none;

                &:after{
                    border-left: 5px solid #FFF;
                } 
            }
        }
        &.active {
            /*background-color: rgba( 243, 244, 246,.7 );*/
            padding-bottom: 10px;

            .nav-item-link {
                background-color: silver;
                color: #fff;

                &:after{
                    border-bottom: none;
                    border-right: 5px solid transparent;
                    border-left: 5px solid transparent;
                    border-top: 5px solid #FFF;
                } 
            }
        }
        .level-2 {
            position: relative;
        }

    }
}

@media only screen and (max-width: 915px) {
    .nav-main > ul > li {
        border-top: none !important;
    }
    
    .nav-main .nav-item-link {
        color: #fff !important;
        font-weight: bold !important;
    }
    
    #ortus-nav-collapse {
        background-color: #092764 !important;
    }

    #ortus-nav-collapse ul li .btn-active {
        border-bottom: none !important;
        border-radius: 5px !important;
        background-color: white;
        color: #092764 !important;
        padding: 5px;
    }
}

@media only screen and (max-width: 1023px) {
    .nav-main {
        transition: all .3s ease;
    }
}
@media only screen and (min-width: 1024px) {
    .nav-main {
        bottom: auto;
        display: block;
        overflow: initial;
        position: relative;
        top: auto;
        right: auto;
        width: auto;
        
        .nav-item-link{
            width: auto;
            white-space: nowrap;
        }
        .nav-item-parent {
            &:after{
                border-bottom: none;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid $dark-grey;
                right: 5px;
            } 
        }
        > ul >li {
            border-top: none;
            display: inline-flex;
            padding: 0.25rem;
            position: relative;
            
            button.nav-item-link {
                padding-right: 20px;
            }
            .nav-item-link {
                &:hover, &:focus {
                    border-radius: 8px;
                    color: #000;

                    &:after {
                        border-top: 5px solid #000;
                    }
                }
                &:focus {
                    background-color: rgba( 243, 244, 246,.7 );
                    border: 1px solid #8fc73e;
                    outline: none;
                }
            }
            &.active {
                border-radius: 8px 8px 0px 0px;
                padding-bottom: 0.25rem;

                .nav-item-link {
                    background-color: transparent;
                    color: #000;

                    &:after{
                        border-bottom: 5px solid #000;
                        border-top: none;
                    } 
                }
            }
            .level-2 {
                position: absolute;
                top:75%;
                /*max-height:450px;*/
                padding-bottom: 15px;
                overflow:auto;
                z-index: 10;
                transform: translateX(-50%);
            }
        }
        > ul li:first-child {
            .level-2 {
                left: 50%;
                transform: translateX(-45%);   
            }
        }
    }
}

#navbar-main .container {
    z-index: 99999;
}
#navbar-main #searchForm #q {
    padding: 10px;
}
#ortus-nav-collapse {
    /*margin-top: 5px;*/
    background-color: #fff;
}
.navbar-brand {
    height: auto;
    padding: 0;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    color: #555;
    background-color: #fff;
}
.opensrc{
    font-weight: bold!important;
}

#ortus-nav-collapse ul li a {
    display: inline-block;
}

#ortus-nav-collapse ul li .btn-active{
    border-bottom: 3px solid $bright-green;
    width: 100%;
    border-radius: 2px;
    transition: 0.5s ease;
}

#ortus-nav-collapse ul li :hover{
    color: $bright-green;
}


/* 
    Styles to show the menu with hover action
*/

.mainnav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.mainnav > ul > li {
    display: inline-block;
    padding: 0 3px;
}
.mainnav ul li {
    position: relative;
}
.header .mainnav > ul > li > a {
    line-height: 100px;
}

.mainnav > ul > li > a, .mainnav > ul > li > button {
    position: relative;
    text-decoration: none;
    font-size: 16px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    &:focus {
        color: $primary;
        outline: 2px auto $primary;
        outline-offset: 2px;
    }
}

.border-ortus:hover{
    border-bottom: 4px solid $primary;
}

.mainnav ul.submenu {
    position: absolute;
    left: 0;
    top: 150%;
    width: 300px;
    text-align: left;
    z-index: 9999;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.mainnav ul.submenu li:first-child {
    border-top: none;
}
/* #mainnav ul.submenu > li {
    border-top: 1px solid #333333;
} */
.mainnav ul li ul li {
    margin-left: 0;
}
.mainnav ul.submenu > li > a {
    display: block;
    text-decoration: none;
    padding: 0 0 0 20px;
    line-height: 35px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.mainnav ul li:hover > ul.submenu {
    top: 141%;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
    visibility: visible;
}